<template>
    <main>
        <HeaderTab :title="$t('global.titre_storage_management')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                    <div v-if="estimation">
                        {{ $t('storage.utilisation_disque') }}
                        <strong>{{ usage }} / {{ quota }} Go ({{ (100 * usage / quota).toFixed(2) }}%)</strong>
                        <b-progress
                            :usage="estimation.usage"
                            :max="estimation.quota"
                            show-progress
                        >
                        </b-progress>
                    </div>

                    <div class="mt-4">
                        <h4>{{ $t('storage.remove_database') }}</h4>
                        <p>{{ $t('storage.remove_database_infos') }}</p>
                        <b-button @click="removeDatabase" variant="primary">
                            {{ $t('storage.remove_database') }}
                        </b-button>
                    </div>
                </div>

				<div class="box">
					<h4>{{ $t('storage.export_database') }}</h4>
					<e-select
						v-model="tablesToExport"
						:options="tables"
						multiple
						:close-on-select="false"
						:clear-on-select="false"
					/>
					<b-button @click="exportDatabase" variant="secondary" class="mt-2">
						<font-awesome-icon
							v-if="isExporting"
							:icon="['far', 'spinner']"
							pulse
						/>
						{{ $t('storage.export_selection') }}
					</b-button>
				</div>
            </div>
        </div>
    </main>
</template>

<script>
import Common from '@/assets/js/common'

export default {
    name: 'StorageManagement',
    data: () => ({
        estimation: {
            usage: 0,
            quota: 0
        },
		isExporting: false,
		tables: [],
		tablesToExport: []
    }),
    computed: {
        usage() {
            return parseFloat((parseFloat(this.estimation.usage.toFixed(2) / 1000000000)).toFixed(2))
        },
        quota() {
            return parseFloat(parseFloat(this.estimation.quota / 1000000000)).toFixed(2)
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.estimation = await this.$storage.getEstimatedQuota()

			this.tables = this.$storage.db.tables.map(t => t.name)
        },
        async removeDatabase() {
            await this.$storage.removeDatabase(this)
            window.location.reload()
        },
		async exportDatabase() {
			this.isExporting = true
			try {
				const json = await this.$storage.databaseToJson(this.tablesToExport)
				Common.downloadJSON(json, 'export.json')
			}
			finally {
				this.isExporting = false
			}
		}
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
    }
}
</script>